import * as React from "react";
import styled from "styled-components";
import { useLocalization } from "gatsby-theme-i18n";
import PageContainer from "../pageContainer.js";
import {
  Button,
  ButtonIconContainerSuffix,
  ButtonRightIcon,
} from "../button.js";
import parseISO from "date-fns/parseISO";
import format from "date-fns/format";
import { BsFillCaretLeftFill, BsFillCaretRightFill } from "react-icons/bs";
import { ArrowContainer, PageLink, Pagination } from "../pagination.js";
import { GatsbyImage } from "gatsby-plugin-image";
import { when } from "../../styles/theme.js";

const translations = {
  en: {
    download: "View report",
  },
  zh: {
    download: "查看報告",
  },
};

const Container = styled(PageContainer)`
  margin-top: 10rem;
  margin-bottom: 10rem;
`;

const InnerContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

const DownloadButton = styled(Button)`
  margin-left: auto;
  margin-right: auto;
  max-width: fit-content;
  position: absolute;
  right: 1rem;
  bottom: 1rem;

  ${when("sm")} {
    position: static;
  }
`;

const LeftArrow = styled(BsFillCaretLeftFill)`
  display: block;
`;

const RightArrow = styled(BsFillCaretRightFill)`
  display: block;
`;

const Reports = styled.div``;

const Report = styled.div`
  padding: 1.5rem;
  background: ${(props) => props.theme.colors.white};
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 1rem;
  margin-top: 2rem;

  &:first-child {
    margin-top: 0;
  }
`;

const CoverContainer = styled.div`
  width: 8.4rem;
`;

const Cover = styled(GatsbyImage)`
  border-radius: 0.5rem;
  transform: translateZ(0);
  overflow: hidden;
`;

const DescriptionContainer = styled.div`
  align-self: flex-start;
  margin-left: ${(props) => props.theme.gutterWidth.xs}rem;

  ${when("sm")} {
    margin-left: ${(props) => props.theme.gutterWidth.sm}rem;
    align-self: center;
  }

  ${when("md")} {
    margin-left: ${(props) => props.theme.gutterWidth.sm}rem;
  }
`;

const ReportTitle = styled.h3`
  margin: 0;
  ${when("sm")} {
    font-size: 2.4rem;
  }
`;

const ReportDate = styled.div`
  color: ${(props) => props.theme.colors.gray};
  font-size: 1.2rem;

  ${when("sm")} {
    font-size: 1.4rem;
  }
`;

const Spacer = styled.div`
  flex: 1;
`;

const FinancialReportsPageSection = ({ financialReports, totalPage, page }) => {
  const { locale } = useLocalization();

  const translation = translations[locale];

  const pages = [];
  for (let i = 0; i < totalPage; i++) {
    pages.push(i + 1);
  }

  return (
    <Container as={"section"}>
      <InnerContainer>
        <Reports>
          {financialReports.map((financialReport, index) => {
            const date = parseISO(financialReport.date);

            return (
              <Report key={index}>
                <CoverContainer>
                  <Cover
                    alt={financialReport.cover?.description}
                    image={financialReport.cover?.gatsbyImageData}
                  />
                </CoverContainer>
                <DescriptionContainer>
                  <ReportTitle>{financialReport.title}</ReportTitle>
                  <ReportDate>{format(date, "dd.MM.yyyy")}</ReportDate>
                </DescriptionContainer>
                <Spacer />
                <DownloadButton as={"a"} href={financialReport.file.publicUrl}>
                  {translation.download}
                  <ButtonIconContainerSuffix>
                    <ButtonRightIcon />
                  </ButtonIconContainerSuffix>
                </DownloadButton>
              </Report>
            );
          })}
        </Reports>
        <Pagination>
          <ArrowContainer
            link={
              page === 1
                ? null
                : `/investor-relationship/financial-reports/${page - 1}`
            }
          >
            <LeftArrow />
          </ArrowContainer>
          {pages.map((pageNum, index) => {
            return (
              <PageLink
                key={index}
                href={`/investor-relationship/announcements/${pageNum}`}
                isActive={pageNum === page}
              >
                {pageNum}
              </PageLink>
            );
          })}
          <ArrowContainer
            link={
              totalPage === page
                ? null
                : `/investor-relationship/announcements/${page + 1}`
            }
          >
            <RightArrow />
          </ArrowContainer>
        </Pagination>
      </InnerContainer>
    </Container>
  );
};

export default FinancialReportsPageSection;
