import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout.js";
import FinancialReportsPageSection from "../components/pageSections/financialReportsPageSection.js";

// markup
const FinancialReportsPage = ({
  data,
  pageContext: { originalPath, totalPage, page },
  uri,
}) => {
  const pageNode = data?.page?.nodes[0];
  if (!pageNode) {
    return null;
  }

  return (
    <Layout
      title={pageNode.title}
      description={pageNode.description}
      uri={uri}
      image={pageNode.image?.localFile.childImageSharp.original.src}
      imageAlt={pageNode.image?.description}
      mainNav={data.mainNav?.nodes[0]}
      globalNav={data.globalNav?.nodes[0]}
      brands={data.brands.nodes[0].brands}
      footerNav={{
        primary: data.primaryFooterNav?.nodes[0],
        secondary: data.secondaryFooterNav?.nodes[0],
      }}
      originalPath={originalPath}
      headerTitle={pageNode.headerTitle}
      headerBackgroundImage={pageNode.headerBackgroundImage}
      settings={data.settings?.nodes[0]}
    >
      <FinancialReportsPageSection
        financialReports={data.financialReports.nodes}
        totalPage={totalPage}
        page={page}
      />
    </Layout>
  );
};

export default FinancialReportsPage;

export const data = graphql`
  query financialReportsPageQuery($hrefLang: String!, $skip: Int!) {
    globalNav: allContentfulNavigation(
      filter: { contentfulid: { eq: "global" }, node_locale: { eq: $hrefLang } }
    ) {
      nodes {
        ...NavLinks
      }
    }
    mainNav: allContentfulNavigation(
      filter: { contentfulid: { eq: "main" }, node_locale: { eq: $hrefLang } }
    ) {
      nodes {
        ...NavLinks
      }
    }
    brands: allContentfulPageSectionBrands(
      filter: {
        node_locale: { eq: $hrefLang }
        contentful_id: { eq: "2kjRmkm5mviLHU4RYXdrP6" }
      }
    ) {
      nodes {
        brands {
          name
          slug
        }
      }
    }
    primaryFooterNav: allContentfulNavigation(
      filter: {
        contentfulid: { eq: "footer-primary" }
        node_locale: { eq: $hrefLang }
      }
    ) {
      nodes {
        ...NavLinks
      }
    }
    secondaryFooterNav: allContentfulNavigation(
      filter: {
        contentfulid: { eq: "footer-secondary" }
        node_locale: { eq: $hrefLang }
      }
    ) {
      nodes {
        ...NavLinks
      }
    }
    settings: allContentfulSiteSettings(
      filter: { node_locale: { eq: $hrefLang } }
    ) {
      nodes {
        ...SiteSettings
      }
    }
    page: allContentfulPage(
      filter: {
        contentfulid: { eq: "financial-reports" }
        node_locale: { eq: $hrefLang }
      }
    ) {
      nodes {
        ...Page
      }
    }
    financialReports: allContentfulFinancialReport(
      sort: { order: DESC, fields: date }
      limit: 20
      skip: $skip
      filter: { node_locale: { eq: $hrefLang } }
    ) {
      nodes {
        title
        file {
          publicUrl
        }
        date
        cover {
          description
          gatsbyImageData(layout: CONSTRAINED, width: 84, height: 112)
        }
        file {
          publicUrl
        }
        node_locale
      }
    }
  }
`;
